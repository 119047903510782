import { HttpErrorResponse } from '@angular/common/http';

function getErrorFromHttpErrorResponse(res: HttpErrorResponse) {
  const { error } = res;
  if (error.details && error.details.length) {
    const details = error.details.map((d: Record<string, unknown>) => {
      if (d && d.property) {
        return `${d.property}: ${d.fail}`;
      }
      return d;
    });
    return `${details.join(' | ')}`;
  } else if (error.error) {
    return `${error.error}`;
  } else {
    return `${res.statusText} (${res.status}) ${
      res.url && res.url.length > 100 ? `${res.url.substr(0, 100)}...` : res.url
    }`;
  }
}

export function getErrorFromResponse(res: any) {
  let errorInstance: HttpErrorResponse | undefined;
  if (res) {
    if (res instanceof HttpErrorResponse) {
      errorInstance = res;
    } else if (res.error instanceof HttpErrorResponse) {
      errorInstance = res.error;
    }
  }
  if (errorInstance) {
    return getErrorFromHttpErrorResponse(errorInstance);
  } else if (res instanceof Error) {
    return `ERROR: ${res.message}`;
  } else if (res && res.error) {
    return res.error.toString();
  } else {
    return '';
  }
}
