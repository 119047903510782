import 'dayjs/locale/en';
import 'dayjs/locale/es';

import { Injectable } from '@angular/core';
import { getBrowserLanguage } from '../helpers/browser-lang.helper';
import dayjs from 'dayjs';

export const DEFAULT_LANGUAGE = 'es';
export const DEFAULT_LOCALE = 'es-ES';
export const AVAILABLE_LANGUAGES = ['es', 'en', 'to'];

@Injectable({ providedIn: 'root' })
export class LocaleService {
  readonly defaultLanguage = DEFAULT_LANGUAGE;
  readonly availableLanguages = AVAILABLE_LANGUAGES;

  getLanguage = (): string => getCurrentLanguage() || DEFAULT_LANGUAGE;
  getLocale = (): string => getCurrentLocale();
}

export function localeIdFactory(localeService: LocaleService): string {
  return localeService.getLanguage();
}

export function getCurrentLanguage(w: Window = window): string {
  const potentialLanguage = w.location.pathname.split('/').filter(Boolean)[0] || getBrowserLanguage(w);
  return potentialLanguage && AVAILABLE_LANGUAGES.includes(potentialLanguage)
    ? potentialLanguage
    : DEFAULT_LANGUAGE;
}

export function getCurrentLocale(w: Window = window): string {
  switch (getCurrentLanguage(w)) {
    case 'es':
      return 'es-ES';
    case 'en':
      return 'en-US';
    default:
      return DEFAULT_LOCALE;
  }
}

export function loadDayjsLocale(language?: string): void {
  const lang = language ?? getCurrentLanguage();
  dayjs.locale(lang);
}

export function localeFactory(language?: string) {
  const browserLang = window.navigator.language;
  const languageDefault = language ?? DEFAULT_LANGUAGE;
  const defaultLang = AVAILABLE_LANGUAGES.includes(browserLang) ? browserLang : languageDefault;

  return defaultLang;
}
