import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@platform/services/api.service';
import { ApiChangeResponse, ApiResponse } from 'emma-common-ts';
import { SkudoUserScope, User, UserVerifyEmailResponse } from 'emma-common-ts/skudo';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocalStorageService, LOCAL_STORAGE_KEY } from './local-storage.service';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SignUpData {}

export const getDefaultUser = (): User => ({ email: '', access_token: '', refresh_token: '', accounts: [] });

@Injectable({ providedIn: 'root' })
export class UserService {
  $user: BehaviorSubject<User | undefined>;
  constructor(private apiService: ApiService, private localStorageService: LocalStorageService) {
    this.$user = new BehaviorSubject(this.localStorageService.get(LOCAL_STORAGE_KEY.CURRENT_USER));
  }

  private setCurrentUser(user: User): BehaviorSubject<User | undefined> {
    this.$user.next(user);
    return this.$user;
  }

  getCurrentUser(): BehaviorSubject<User | undefined> {
    return this.$user;
  }

  verify(user: Partial<User> | null | undefined): Observable<ApiResponse<SkudoUserScope>> {
    if (!user?.access_token) {
      return throwError(() => 'No user found');
    }

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${user.access_token}`);
    return this.apiService.get<ApiResponse<SkudoUserScope>>(
      `/oauth2/scope`,
      {},
      {
        headers,
      }
    );
  }

  fetchUser(user: User | undefined = this.getCurrentUser().value): Observable<boolean> {
    return this.verify(user).pipe(
      map((userScopeResponse) => {
        const { data } = userScopeResponse;
        if (data) {
          this.updateUser({
            ...data.user,
            accounts: data.accounts,
          });
          return true;
        }
        return false;
      })
    );
  }

  saveUser(user: User): BehaviorSubject<User | undefined> {
    const updated = { ...getDefaultUser(), ...user };
    this.localStorageService.set(LOCAL_STORAGE_KEY.CURRENT_USER, updated);
    return this.setCurrentUser(updated);
  }

  updateUser(user: Partial<User>): BehaviorSubject<User | undefined> {
    const updated = { ...getDefaultUser(), ...this.$user.value, ...user };
    return this.saveUser(updated);
  }

  removeUser(): BehaviorSubject<User | undefined> {
    this.$user.next(undefined);
    this.localStorageService.remove(LOCAL_STORAGE_KEY.CURRENT_USER);
    return this.$user;
  }

  /**
   * Registra un nuevo usuario en la API
   */
  signUp(data: SignUpData): Observable<ApiChangeResponse> {
    return this.apiService.post('/user/sign-up', data);
  }

  forgotPassword(email: string): Observable<ApiChangeResponse> {
    return this.apiService.post('/user/forgot-password', { email });
  }

  changePassword(password: string, oldPassword: string): Observable<ApiChangeResponse> {
    return this.apiService.put('/user/password', { password, oldPassword });
  }

  recoverPassword(password: string, authCode: string): Observable<ApiChangeResponse> {
    return this.apiService.put('/user/recover-password', {
      password,
      token: authCode,
    });
  }

  activateAccount(authToken: string): Observable<ApiChangeResponse> {
    return this.apiService.put('/user/activate', { token: authToken });
  }

  verifyIfUserEmailExists(email: string): Observable<ApiResponse<UserVerifyEmailResponse>> {
    return this.apiService.post<ApiResponse<UserVerifyEmailResponse>>('/user/verify-email', { email });
  }
}
