<div class="input-group">
    <div 
        [ngClass]="{
            'input-group-switch': siblingTemplate
        }">
        <ui-switch
            [class]="'emma-ui-switch emma-ui-switch-' + checkedColor"
            [size]="size"
            [checked]="innerValue"
            [disabled]="disabled || readonly"
            [attr.loading]="loading"
            (change)="onChange($event)"
            [checkedLabel]="checkedLabel"
            [uncheckedLabel]="uncheckedLabel"
        ></ui-switch>
    </div>
    <div *ngIf="siblingTemplate" class="input-group-text input-group-text-transparent">
        <ng-template
            [ngTemplateOutlet]="siblingTemplate"
            [ngTemplateOutletContext]="{
                checked: checked,
                reverse: reverse
            }"
        ></ng-template>
    </div>
    <div *ngIf="labelTemplate" class="input-group-text input-group-text-transparent">
        <ng-template
            [ngTemplateOutlet]="labelTemplate"
            [ngTemplateOutletContext]="{
                checked: checked,
                reverse: reverse
            }"
        ></ng-template>
    </div>
    <ng-container *ngIf="!labelTemplate && label">
        <span
            [@fadeInOut]
            class="input-group-text input-group-text-transparent"
            (click)="onChange(!innerValue)"
            >{{ label }}</span
        >
    </ng-container>
</div>
<div *ngIf="checked && descriptionOn" class="input-group__footer">
    <p>{{ descriptionOn }}</p>
</div>
<div *ngIf="!checked && descriptionOff" class="input-group__footer">
    <p>{{ descriptionOff }}</p>
</div>
