/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'skudo-script-injector-twak-chat',
  template: '',
})
export class ScriptInjectorTwakChatComponent implements OnInit {
  static inject(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w = window as any;
    w.Tawk_API = w.Tawk_API || {};
    w.Tawk_LoadStart = new Date();
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/5e8b49d569e9320caac0c9f1/default';
    script.setAttribute('crossorigin', '*');
    document.body.appendChild(script);
  }

  ngOnInit(): void {
    setTimeout(() => ScriptInjectorTwakChatComponent.inject(), 100);
  }
}
