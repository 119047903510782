import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Daterangepicker } from 'ng2-daterangepicker';
import { SkudoLineSeriesChartComponent } from './skudo-line-series-chart/skudo-line-series-chart.component';
import { SkudoBaseChartComponent } from './skudo-base-chart/skudo-base-chart.component';
import { EMMAPortletModule } from '@emma-components/emma-portlet.module';
import { EMMALoadingModule } from '@emma-components/emma-loading.module';
import { EMMASelectModule } from '@emma-components/forms/emma-select.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    Daterangepicker,
    EMMAPortletModule,
    EMMALoadingModule,
    EMMASelectModule,
  ],
  exports: [SkudoLineSeriesChartComponent],
  declarations: [SkudoLineSeriesChartComponent, SkudoBaseChartComponent],
})
export class SkudoChartModule {}
