import { getCurrentLocale } from '@platform/services/locale.service';
import { DATE_FORMATS } from 'emma-common-ts';
import {
  camelCase,
  capitalize,
  get,
  kebabCase,
  lowerCase,
  lowerFirst,
  memoize,
  snakeCase,
  startCase,
  upperCase,
  upperFirst,
} from 'lodash';
import moment from 'moment';

import { isDateLabel } from './is-function.helper';

export function formatNumber(number = 0, options: Intl.NumberFormatOptions = {}): string {
  const num = Number(number);
  if (isNaN(num)) {
    return '';
  }
  const locale = getCurrentLocale();
  if (options.style === 'currency') {
    options = {
      currency: 'EUR',
      ...options,
    };
  }
  if (num % 1 !== 0) {
    options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...options,
    };
  } else {
    options = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      ...options,
    };
  }
  return num.toLocaleString(locale, options);
}

function __formatFilename(filename: string): string {
  if (!filename) {
    return filename;
  }
  const str = String(filename);
  return str.replace(/[^a-z0-9.]+/gi, '_').toLowerCase();
}
export const formatFilename = memoize(__formatFilename);

export type StringCase =
  | 'camelcase' // camel-case => CamelCase
  | 'capitalcase' // capiTAL Case => Capital case
  | 'kebabcase' // Kebab Case => kebab-case
  | 'lower' // LO--WER => lo--wer
  | 'lowercase' // LOWER--CASE => lower case
  | 'lowerfirst' // LOWER FIRST => lOWER FIRST
  | 'snakecase' // Snake CASE => snake_case
  | 'startcase' // start_case => Start Case
  | 'upper' // up_per => UP_PER
  | 'uppercase' // upperCase => UPPER CASE
  | 'upperfirst' // upPER firsT => UpPER firsT
  | 'uppersnake'; // Upper snake => UPPER_SNAKE

export function formatStringCase(value: string, stringCase: StringCase): string {
  const str = String(value);
  switch (stringCase) {
    case 'lower':
      return str.toLocaleLowerCase();
    case 'upper':
      return str.toLocaleUpperCase();
    case 'uppercase':
      return upperCase(str);
    case 'lowercase':
      return lowerCase(str);
    case 'startcase':
      return startCase(str);
    case 'capitalcase':
      return capitalize(str);
    case 'camelcase':
      return camelCase(str);
    case 'kebabcase':
      return kebabCase(str);
    case 'lowerfirst':
      return lowerFirst(str);
    case 'upperfirst':
      return upperFirst(str);
    case 'snakecase':
      return snakeCase(str);
    case 'uppersnake':
      return snakeCase(str).toLocaleUpperCase();
    default:
      return str;
  }
}

function __formatDateLabel(label: string) {
  return isDateLabel(label) ? moment(label, DATE_FORMATS.API).format(DATE_FORMATS.DAY_MONTH) : label;
}
export const formatDateLabel = memoize(__formatDateLabel);

export function ellipsis(str: string, maxLength: number, ellipsisChar = '…'): string {
  if (maxLength <= 0) {
    return '';
  }
  if (str.length > maxLength) {
    const trimmed = str.trim();
    if (trimmed.length > maxLength) {
      return trimmed.substring(0, maxLength - ellipsisChar.length).trim() + ellipsisChar;
    }
    return trimmed;
  }
  return str;
}

export function ellipsisMiddle(str: string, maxLength: number, ellipsisChar = '…'): string {
  // Cannot be less than 3 chars
  if (maxLength < ellipsisChar.length + 2) {
    return ellipsis(str, maxLength);
  }
  if (str.length > maxLength) {
    const trimmed = str.trim();
    if (trimmed.length > maxLength) {
      const max = maxLength - ellipsisChar.length;
      const firstChunk = Math.ceil(max / 2);
      const firstPart = trimmed.slice(0, firstChunk);
      const lastPart = trimmed.slice(trimmed.length - Math.floor(max - firstChunk));
      return firstPart.trim() + ellipsisChar + lastPart.trim();
    }
    return trimmed;
  }
  return str;
}

export const splitText = (text: string, maxChars = 80): string[] => {
  const lines: string[] = [];
  const splitted = text.split(' ');
  let line = '';
  do {
    const pop = splitted.shift();
    if (pop) {
      if (line.length + pop.length + 1 > maxChars) {
        if (line) {
          lines.push(line);
        }
        line = pop;
      } else if (line) {
        line += ` ${pop}`;
      } else {
        line = pop;
      }
    }
  } while (splitted.length);
  if (line) {
    lines.push(line);
  }
  return lines;
};

export const isStringPresent = (needle: string, haystack: unknown): boolean => {
  if (Array.isArray(haystack)) {
    return haystack.some((val) => isStringPresent(needle, val));
  } else if (haystack && get(haystack, 'label')) {
    return String(get(haystack, 'label')).toLowerCase().includes(needle);
  } else if (haystack) {
    return String(haystack).toLowerCase().includes(needle);
  } else {
    return false;
  }
};

export const formatJson = (json: string, indent = 2): string => {
  try {
    return JSON.stringify(JSON.parse(json), null, indent);
  } catch (e) {
    return json;
  }
};
