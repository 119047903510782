import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AUTH_TYPE } from '../auth-wrapper.component';
import { UserService } from '@skudo/services/user.service';
import { apiFeedback } from '@platform/helpers/api-feedback.helper';
import { NotificationsService } from '@platform/services/notifications.service';
import { ROUTE } from 'emma-common-ts/skudo';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { getNotificationsPassword } from '@skudo/helpers/auth.helper';

@Component({
  selector: 'skudo-recover-password-page',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SkudoRecoverPasswordPageComponent implements OnInit {
  @ViewChild('form', { static: false }) public form!: NgForm;
  loading = new Set<string>();
  type = AUTH_TYPE.RECOVER_PASS;

  authCode = '';
  email = '';
  password = '';
  repeatPassword = '';
  isPasswordValid = false;
  notificationsKey = 'passwordFeedback';

  constructor(
    public notifications: NotificationsService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.authCode = this.activatedRoute.snapshot.queryParams['authCode'];
    this.email = decodeURIComponent(this.activatedRoute.snapshot.queryParams['email']);
  }

  isValid() {
    return Boolean(this.form?.valid && this.isPasswordValid);
  }

  onSubmit(ev: Event) {
    ev.preventDefault();
    if (this.isValid()) {
      this.onSave();
    }
  }

  onSave() {
    this.loading.add('recover');
    this.userService
      .recoverPassword(this.password, this.authCode)
      .pipe(apiFeedback('recover', this.notifications, this.loading))
      .subscribe({
        next: () => {
          this.notifications.setInfo('password', $localize`Contraseña cambiada correctamente.`);
          this.navigateToLogout();
        },
        error: () => {
          this.router.navigateByUrl(ROUTE.LOGIN).catch((error) => console.error(error));
        },
      });
  }

  showWarning() {
    const notifications = getNotificationsPassword(this.notificationsKey, this.password, this.repeatPassword);
    this.isPasswordValid = notifications.length === 0;
    if (this.isPasswordValid) {
      this.notifications.delete(this.notificationsKey);
    } else {
      notifications.forEach((notification) => this.notifications.set(this.notificationsKey, notification));
    }
  }

  private navigateToLogout() {
    this.router.navigateByUrl(ROUTE.LOGOUT).catch((error) => console.error(error));
  }
}
