import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';
import { EMMAPipesModule } from '@emma-pipes/emma-pipes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { QRCodeModule } from 'angularx-qrcode';
import { UiSwitchModule } from 'ngx-ui-switch';

import { EMMAChartModule } from './charts/emma-chart.module';
import { EMMAAddWidgetComponent } from './emma-add-widget.component';
import { EMMAAudienceTypeInfoComponent } from './emma-audience-type-info.component';
import { EMMADashboardComponent } from './emma-dashboard.component';
import { EMMADialogComponent } from './emma-dialog.component';
import { EMMADropdownModule } from './emma-dropdown.module';
import { EMMAEditableWidgetComponent } from './emma-editable-widget.component';
import { EMMAFilterItemSummaryComponent } from './emma-filter-summary-info/emma-filter-item-summary.component';
import { EMMAFilterSummaryInfoComponent } from './emma-filter-summary-info/emma-filter-summary-info.component';
import { EMMAInfoIconModule } from './emma-info-icon.module';
import { EMMALoadingModule } from './emma-loading.module';
import { EMMANotificationModule } from './emma-notification.module';
import { EMMAPageComponent } from './emma-page.component';
import { EMMAPaginationModule } from './emma-pagination.module';
import { EMMAPortletComponent } from './emma-portlet.component';
import { EMMAPortletModule } from './emma-portlet.module';
import { EMMASelectableKeyChartComponent } from './emma-selectable-key-chart.component';
import { EMMASelectableMultipleKeyChartComponent } from './emma-selectable-multiple-key-chart.component';
import { EMMATableModule } from './emma-table/emma-table.module';
import { EMMATextVariableListComponent } from './emma-text-variable-list.component';
import { EMMAUserMenuComponent } from './emma-user-menu.component';
import { EMMAFormModule } from './forms/emma-form.module';
import { EMMAConfirmationModalComponent } from './modals/emma-confirmation-modal.component';
import { EMMAContactPlanConfiguratorModalComponent } from './modals/emma-contact-plan-configurator-modal.component';
import { EMMADraftCreateAudienceConfirmationModalComponent } from './modals/emma-draft-create-audience-confirmation-modal.component';
import { EMMAExportModalComponent } from './modals/emma-export-modal.component';
import { EMMAExportSegmentModalComponent } from './modals/emma-export-segment-modal.component';
import { EMMAFilterExecutionModalComponent } from './modals/emma-filter-execution-modal.component';
import { EMMAImportDataSegmentModalComponent } from './modals/emma-import-data-segment-modal.component';
import { EMMAImportDataSegmentPreviewModalComponent } from './modals/emma-import-data-segment-preview-modal.component';
import { EMMAModalModule } from './modals/emma-modal.module';
import { EMMANewMessageModalComponent } from './modals/emma-new-message-modal.component';
import { EMMANewWidgetModalComponent } from './modals/emma-new-widget-modal.component';
import { EMMAOwnerMessageModalComponent } from './modals/emma-owner-message-modal.component';
import { EMMARequestPlanConfiguratorModalComponent } from './modals/emma-request-plan-configurator-modal.component';
import { EMMASaveDeleteFunnelModalComponent } from './modals/emma-save-delete-funnel-modal.component';
import { EMMAShareDashboardModalComponent } from './modals/emma-share-dashboard-modal.component';
import { EMMATrackerLinkDetailsModalComponent } from './modals/emma-tracker-link-details-modal.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    EMMAChartModule,
    EMMADirectivesModule,
    EMMAFormModule,
    EMMAInfoIconModule,
    EMMALoadingModule,
    EMMAModalModule,
    EMMANotificationModule,
    EMMAPipesModule,
    EMMAPortletModule,
    EMMATableModule,
    EMMAPaginationModule,
    FormsModule,
    NgxDnDModule,
    QRCodeModule,
    ReactiveFormsModule,
    RouterModule,
    UiSwitchModule,
  ],
  declarations: [
    EMMAAddWidgetComponent,
    EMMAAudienceTypeInfoComponent,
    EMMAFilterExecutionModalComponent,
    EMMAFilterSummaryInfoComponent,
    EMMAFilterItemSummaryComponent,
    EMMAConfirmationModalComponent,
    EMMAContactPlanConfiguratorModalComponent,
    EMMARequestPlanConfiguratorModalComponent,
    EMMADraftCreateAudienceConfirmationModalComponent,
    EMMADashboardComponent,
    EMMADialogComponent,
    EMMAEditableWidgetComponent,
    EMMAExportModalComponent,
    EMMAExportSegmentModalComponent,
    EMMAImportDataSegmentModalComponent,
    EMMAImportDataSegmentPreviewModalComponent,
    EMMANewMessageModalComponent,
    EMMANewWidgetModalComponent,
    EMMAOwnerMessageModalComponent,
    EMMAPageComponent,
    EMMASaveDeleteFunnelModalComponent,
    EMMASelectableKeyChartComponent,
    EMMASelectableMultipleKeyChartComponent,
    EMMAShareDashboardModalComponent,
    EMMATextVariableListComponent,
    EMMATrackerLinkDetailsModalComponent,
    EMMAUserMenuComponent,
  ],
  exports: [
    EMMAAddWidgetComponent,
    EMMAAudienceTypeInfoComponent,
    EMMAFilterExecutionModalComponent,
    EMMAFilterSummaryInfoComponent,
    EMMAConfirmationModalComponent,
    EMMAContactPlanConfiguratorModalComponent,
    EMMARequestPlanConfiguratorModalComponent,
    EMMADraftCreateAudienceConfirmationModalComponent,
    EMMADashboardComponent,
    EMMADialogComponent,
    EMMADropdownModule,
    EMMAEditableWidgetComponent,
    EMMAExportModalComponent,
    EMMAExportSegmentModalComponent,
    EMMAImportDataSegmentModalComponent,
    EMMAImportDataSegmentPreviewModalComponent,
    EMMAModalModule,
    EMMANewMessageModalComponent,
    EMMANewWidgetModalComponent,
    EMMAOwnerMessageModalComponent,
    EMMAPageComponent,
    EMMAPortletComponent,
    EMMASaveDeleteFunnelModalComponent,
    EMMASelectableKeyChartComponent,
    EMMASelectableMultipleKeyChartComponent,
    EMMAShareDashboardModalComponent,
    EMMATextVariableListComponent,
    EMMATrackerLinkDetailsModalComponent,
    EMMAUserMenuComponent,
    EMMAPipesModule,
    EMMATableModule,
    NgbModule,
  ],
})
export class EMMAUIModule {}
