import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AUTH_TYPE } from '../auth-wrapper.component';
import { UserService } from '@skudo/services/user.service';
import { apiFeedback } from '@platform/helpers/api-feedback.helper';
import { NotificationsService } from '@platform/services/notifications.service';
import { ROUTE } from 'emma-common-ts/skudo';
import { Router } from '@angular/router';

@Component({
  selector: 'skudo-forgot-page',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SkudoForgotPasswordPageComponent {
  loading = new Set<string>();
  type = AUTH_TYPE.FORGOT_PASS;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('email') emailText = '';

  constructor(
    public notifications: NotificationsService,
    private userService: UserService,
    private router: Router
  ) {}

  isValid() {
    return Boolean(this.emailText);
  }

  forgotPassword() {
    this.loading.add('recover');
    this.userService
      .forgotPassword(this.emailText)
      .pipe(apiFeedback('recover', this.notifications, this.loading))
      .subscribe({
        next: () => {
          this.notifications.setInfo(
            'recover',
            $localize`¡Guay! Te hemos enviado las instrucciones de recuperación a tu correo.`
          );
          this.navigateToLogin();
        },
      });
  }

  private navigateToLogin() {
    this.router.navigateByUrl(ROUTE.LOGIN).catch((error) => console.error(error));
  }
}
