import { Router } from '@angular/router';
import { ROUTE } from 'emma-common-ts/emma';
import { EMBED_URL_PARAM_NAME } from '@platform/constants/embed.constants';
import { WINDOW_VAR_TOKEN_NAME } from '@platform/constants/auth.constants';

export interface BackToLoginInfo {
  from?: string;
  endpoint?: string | null;
  addEmbedParam?: boolean;
}

export class BackToLoginService {
  loginRoutes: ROUTE[] = [ROUTE.LOGIN];
  constructor(protected router: Router, loginRoutes?: ROUTE[]) {
    if (loginRoutes) {
      this.loginRoutes = loginRoutes;
    }
  }

  backToLogin(info: BackToLoginInfo) {
    console.info('Back to login', info);
    if (info.from && !this.loginRoutes.some((loginRoute) => info.from?.startsWith(loginRoute))) {
      const w = window as any;
      const routeLogin = w[WINDOW_VAR_TOKEN_NAME] ? ROUTE.AUTO_LOGIN : ROUTE.LOGIN;
      const queryParams = {
        returnUrl: info.from || this.router.url,
      };
      if (info.addEmbedParam) {
        queryParams[EMBED_URL_PARAM_NAME] = true;
      }
      /*
        No se usa navigateByUrl porque la documentación no refleja que SE IGNORAN LOS NavigationExtras!
        https://github.com/angular/angular/issues/22668
        https://github.com/angular/angular/issues/18798
      */
      return this.router.navigate([routeLogin], { queryParams });
    } else if (
      !this.loginRoutes.some((loginRoute) => this.router.url.startsWith(loginRoute)) &&
      !this.router.url.startsWith(ROUTE.LOGOUT)
    ) {
      return this.router.navigateByUrl(ROUTE.LOGIN);
    }
    return Promise.reject();
  }
}
