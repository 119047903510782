import { ERROR_CODES, isInEnum } from 'emma-common-ts';

export const messages = {
  [ERROR_CODES.OAUTH_USER_INVALID]: $localize`El usuario o clave no es correcto.`,
  [ERROR_CODES.ACCESS_BLOCKED_OR_NOT_PERMISSIONS]: $localize`El usuario no está asociado a una cuenta o su acceso ha sido bloqueado.`,
  [ERROR_CODES.TOKEN_NOT_EXIST]: $localize`No se ha realizado una conexión con Facebook. Contacta con soporte.`,
  [ERROR_CODES.TOKEN_EXPIRED]: $localize`La conexión con Facebook ha caducado. Renuevala.`,
  [ERROR_CODES.AD_ACCOUNT_NOT_EXIST]: $localize`No se ha indicado la cuenta de anuncios de Facebook. Añadela en preferencias de la app.`,
  [ERROR_CODES.NATIVE_AD_TEMPLATE_DUPLICATED]: $localize`Ya existe una plantilla con el mismo templateId.`,
  [ERROR_CODES.NATIVE_AD_TEMPLATE_DEFAULT]: $localize`No se puede modificar la plantilla por defecto.`,
  [ERROR_CODES.ACCESS_TOKEN_EXPIRED]: $localize`Sesión expirada. Ingresa las credenciales.`,
  [ERROR_CODES.SKUDO_CAMPAIGN_HAS_ACTIVE_OFFERS]: $localize`No se puede eliminar la campaña. La campaña tiene ofertas activas.`,
  [ERROR_CODES.SKUDO_OFFER_HAS_ACTIVE_URLS]: $localize`No se puede eliminar la oferta. La oferta tiene direcciones URL activas.`,
  [ERROR_CODES.SKUDO_PROVIDER_HAS_ACTIVE_URLS]: $localize`No se puede eliminar el proveedor. El proveedor tiene direcciones URL activas.`,
  [ERROR_CODES.SKUDO_AUTHORIZATION_CODE_INVALID]: $localize`El enlace no es válido.`,
  [ERROR_CODES.SKUDO_AUTHORIZATION_CODE_EXPIRED]: $localize`El enlace ha cadudado.`,
  [ERROR_CODES.NO_CSRF_TOKEN_BODY]: $localize`No se ha recibido el CSRF token.`,
  [ERROR_CODES.NO_CSRF_TOKEN_COOKIE]: $localize`No se ha recibido la cookie con el CSRF token.`,
  [ERROR_CODES.FAILED_VERIFY_DOUBLE_CSRF]: $localize`Fallo al comprobar el CSRF token.`,
  [ERROR_CODES.FAILED_VERIFY_ID_TOKEN_GOOGLE]: $localize`Fallo al comprobar el Google ID.`,
  [ERROR_CODES.GOOGLE_PROFILE_INFO]: $localize`No se ha podido obtener toda la información necesaria del perfil de Google.`,
  [ERROR_CODES.USER_NOT_ACTIVATED]: $localize`El usuario no ha sido activado.`,
  [ERROR_CODES.USER_NOT_REGISTERED]: $localize`El usuario todavía no se ha registrado`,
  [ERROR_CODES.OAUTH_ERROR]: $localize`Ha ocurrido un error con la autenticación`,
  [ERROR_CODES.GOOGLE_ADS_CONNECTION_FAIL]: $localize`El token ha expirado o ya no tienes permisos sobre la cuenta. Vuelve a realizar la conexión con Google Ads.`,
};

export const getMessage = (error: number): string => {
  if (isInEnum<ERROR_CODES>(ERROR_CODES, error) && error in messages) {
    return messages[error];
  }
  return '';
};
