import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { NotificationsService } from '@platform/services/notifications.service';
import { Notification, NotificationLevel } from 'emma-common-ts';

import {
  PlatformNotificationActionsRightTemplateDirective,
  PlatformNotificationDismissButtonTemplateDirective,
} from './platform-notification-feedback.directive';

@Component({
  selector: 'platform-notification-feedback',
  templateUrl: './platform-notification-feedback.component.html',
})
export class PlatformNotificationFeedbackComponent {
  @Input() notifications: Notification[] = [];
  @Input() notification?: Notification;
  NOTIFICATION_LEVEL = NotificationLevel;
  /** Template de acción a la derecha */
  @ContentChild(PlatformNotificationActionsRightTemplateDirective, { read: TemplateRef, static: false })
  notificationActionsRightTemplateDirective!: TemplateRef<any>;
  /** Template botón cerrar notificación */
  @ContentChild(PlatformNotificationDismissButtonTemplateDirective, { read: TemplateRef, static: false })
  notificationDismissButtonTemplateDirective!: TemplateRef<any>;

  constructor(private notificationsService: NotificationsService) {}

  onDismiss(notification: Notification) {
    this.notificationsService.delete(notification.key);
  }

  onDontShowMore(notification: Notification) {
    this.notificationsService.setDontShowMore(notification.key);
  }

  onContactButton(notification: Notification) {
    this.onDismiss(notification);
    window.open(notification.helpLinkUrl, '_blank');
  }
}
