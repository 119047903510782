import { LocalStorageService, LOCAL_STORAGE_KEY } from '@skudo/services/local-storage.service';
import {
  Notification,
  NotificationIcon,
  NotificationLevel,
  checkPasswordLength,
  checkPasswordLowercase,
  checkPasswordNumbers,
  checkPasswordSpecialChars,
  checkPasswordUppercase,
} from 'emma-common-ts';
import { AccountUser, MIN_PASSWORD_LENGTH, User } from 'emma-common-ts/skudo';
import { getMessage } from '@platform/i18n/api-errors.i18n';

export const getAuthKeys = (): { accessToken: string; apiKey: string } | null => {
  const localStorageService = new LocalStorageService();
  const currentAccount = localStorageService.get<AccountUser>(LOCAL_STORAGE_KEY.CURRENT_ACCOUNT);
  const currentUser = localStorageService.get<User>(LOCAL_STORAGE_KEY.CURRENT_USER);
  if (currentAccount && currentUser) {
    const accessToken = currentUser.access_token;
    const apiKey = currentAccount.apiKey;
    return {
      accessToken,
      apiKey,
    };
  }
  return null;
};

export const getNotificationError = (key: string, message: string) => {
  return new Notification({
    key,
    message,
    level: NotificationLevel.ERROR,
    dismissable: true,
    icon: NotificationIcon.ERROR,
    contactButton: false,
    dontShowMore: false,
  });
};

export const getNotificationsPassword = (
  notificationsKey: string,
  password: string,
  repeatPassword: string
) => {
  const notifications: Notification[] = [];
  if (password !== repeatPassword) {
    notifications.push(
      getNotificationError(notificationsKey, $localize`La nueva contraseña debe coincidir en ambos campos.`)
    );
  }
  if (!checkPasswordUppercase(password)) {
    notifications.push(
      getNotificationError(
        notificationsKey,
        $localize`La contraseña debe tener al menos una letra mayúscula.`
      )
    );
  }
  if (!checkPasswordLowercase(password)) {
    notifications.push(
      getNotificationError(
        notificationsKey,
        $localize`La contraseña debe tener al menos una letra minúscula.`
      )
    );
  }
  if (!checkPasswordNumbers(password)) {
    notifications.push(
      getNotificationError(notificationsKey, $localize`La contraseña debe tener al menos un número.`)
    );
  }
  if (!checkPasswordSpecialChars(password)) {
    notifications.push(
      getNotificationError(
        notificationsKey,
        $localize`La contraseña debe tener al menos un caracter especial de entre los siguientes: !@#$%^&*()_+¡¿?`
      )
    );
  }
  if (!checkPasswordLength(password, MIN_PASSWORD_LENGTH)) {
    notifications.push(
      getNotificationError(
        notificationsKey,
        $localize`La nueva contraseña debe tener un mínimo de ${MIN_PASSWORD_LENGTH} caracteres.`
      )
    );
  }
  return notifications;
};

export const getSocialLoginNotificationError = (key: string, errorCode: number) => {
  return new Notification({
    key,
    message: getMessage(errorCode),
    level: NotificationLevel.ERROR,
    dismissable: true,
    icon: NotificationIcon.ERROR,
    contactButton: false,
    dontShowMore: false,
  });
};
