import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { DateChangeService } from '@platform/services/date-change.service';
import { DateRange, TIME_GROUP } from 'emma-common-ts';
import { takeUntil } from 'rxjs/operators';

import { OnDateChange } from '../../_helpers/emma-lifecycle.interface';
import { EMMADatePickerModel } from './emma-date-picker.component';
import { RELATIVE_DATE, RELATIVE_RANGE } from './emma-date-picker.helpers';
import { EMMAFormElementComponent } from './emma-form-element.component';

const EMMA_DATE_RANGE_GROUP_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => EMMADateRangeGroupPickerComponent),
  multi: true,
};

const EMMA_DATE_RANGE_GROUP_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => EMMADateRangeGroupPickerComponent),
  multi: true,
};

@Component({
  selector: 'emma-date-range-group-picker',
  templateUrl: './emma-date-range-group-picker.component.html',
  providers: [
    EMMA_DATE_RANGE_GROUP_CONTROL_VALUE_ACCESSOR,
    EMMA_DATE_RANGE_GROUP_VALIDATOR,
    {
      provide: EMMAFormElementComponent,
      useExisting: EMMADateRangeGroupPickerComponent,
    },
  ],
})
export class EMMADateRangeGroupPickerComponent
  extends EMMAFormElementComponent
  implements OnInit, OnDateChange, Validator
{
  relativeDateOptions = [
    RELATIVE_DATE.TODAY,
    RELATIVE_DATE.YESTERDAY,
    RELATIVE_RANGE.LAST_7_DAYS,
    RELATIVE_RANGE.LAST_30_DAYS,
    RELATIVE_RANGE.LAST_MONTH,
    RELATIVE_RANGE.CURRENT_MONTH,
  ];
  @Output() override $change = new EventEmitter<DateRange>();
  @Input() hasGroupBy = false;
  @Input() override containerClass = 'd-flex align-items-center m-subheader';
  @Input() color = '';
  @Input() reducedSize = false;
  btnClass = '';
  classes: string[] = [];

  get dateRange() {
    return this._dateRange;
  }
  set dateRange(dateRange: DateRange) {
    this._dateRange = dateRange;
    this.$change.emit(dateRange);
  }
  _dateRange: DateRange;

  override ngOnInit(): void {
    this.updateClasses();
    this.classes = [
      'm-btn--custom m-btn--pill m--font-inverse-brand',
      this.color ? `m-btn--hover-${this.color}` : 'm-btn--hover-emmagreen',
    ];

    this.btnClass = this.classes.join(' ');
  }

  constructor(private dateService: DateChangeService) {
    super();
    this._dateRange = this.dateService.getCurrentDate();
    this.dateService
      .getChangeEmitter()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((dateRange) => (this._dateRange = dateRange));
  }
  dateChanged(startDay: string, endDay: string): void {
    this.dateRange = { ...this.dateRange, startDay, endDay };
  }
  onDateRangeChange(change: EMMADatePickerModel): void {
    this.dateRange = { ...this.dateRange, ...change };
  }
  onTimeGroupChange(timeGroup: TIME_GROUP): void {
    this.dateRange = { ...this.dateRange, timeGroup };
  }
}
