<div [ngClass]="_containerClass">
    <div class="dateGroupingContainer" *ngIf="hasGroupBy">
        <emma-time-group-picker
            [timeGroup]="_dateRange.timeGroup"
            ($change)="onTimeGroupChange($event)"
            [buttonClass]="btnClass"
            [reducedSize]="reducedSize"
        ></emma-time-group-picker>
    </div>
    <div class="dateSelectorContainer m--margin-left-15">
        <emma-date-picker
            [fromDate]="_dateRange.startDay"
            [toDate]="_dateRange.endDay"
            fromDateKey="startDay"
            [reducedSize]="reducedSize"
            toDateKey="endDay"
            [pickRange]="true"
            [relativeDateOptions]="relativeDateOptions"
            ($change)="onDateRangeChange($event)"
            placement="bottom-right"
            [buttonClass]="btnClass"
        ></emma-date-picker>
    </div>
</div>
