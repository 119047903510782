<div [ngClass]="_containerClass">
    <input
        #inputElement
        [(ngModel)]="value"
        [ngClass]="_inputClass"
        [autocomplete]="autocomplete"
        [autocapitalize]="autocapitalize"
        [spellcheck]="spellcheck"
        [disabled]="disabled"
        [required]="!!required"
        [minlength]="minlength"
        [maxlength]="maxlength"
        [placeholder]="placeholder"
        [autofocus]="autofocus"
        [readonly]="readonly"
        [type]="type"
        [attr.inputmode]="inputmode"
        [min]="min"
        [max]="max"
        [step]="step"
        [tabindex]="tabindex"
        (focus)="onFocus()"
        (blur)="onBlur()"
        (change)="onChange($event)"
        (keydown)="onKeyDown($event)"
        (keypress)="$keypress.emit($event)"
        (keyup)="$keyup.emit($event)"
    />
    <ul
        #suggestionList
        [class]="
            'emma-input__suggestions ' +
            (dropdownPosition === 'top' ? 'emma-input__suggestions--top' : 'emma-input__suggestions--botom')
        "
        *ngIf="isOpen"
        (mouseenter)="onSuggestionsHover()"
        (mouseleave)="onSuggestionsLeave()"
    >
        <li
            *ngFor="let suggestion of visibleValues; index as index; trackBy: trackByFn"
            [class]="index === selectedSuggestion ? 'active' : ''"
            (mousedown)="onSelect(suggestion.value)"
            (mouseover)="onMouseHover(index)"
            [title]="suggestion.label ? suggestion.value + ': ' + suggestion.label : false"
        >
            {{ suggestion.value }}
            <div *ngIf="suggestion.label" class="emma-input__suggestion-help">
                {{ suggestion.label }}
            </div>
        </li>
    </ul>
    <div *ngIf="copyToClipboard" class="emma-copyToClipboard" emmaCopyToClipboard [copyValue]="value"></div>
    <div
        class="helpMessageAtError"
        #popover="ngbPopover"
        *ngIf="showHelpMessageAtError"
        [ngbPopover]="helpMessageAtError"
        [placement]="helpMessagePosition"
        triggers="manual"
    ></div>
</div>
