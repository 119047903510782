import { HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { LoginResponse } from 'emma-common-ts/skudo';
import { tap } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  login$ = new EventEmitter<string>();
  logout$ = new EventEmitter<void>();

  constructor(private apiService: ApiService) {}

  login = (username: string, password: string) => {
    return this.apiService
      .post<LoginResponse>(
        '/oauth2/login',
        { username, password },
        {
          headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' }),
        }
      )
      .pipe(tap(() => this.login$.next(username)));
  };

  logout() {
    this.logout$.next();
  }

  autoLogin = (token: string) =>
    this.apiService.post<LoginResponse>(
      '/oauth2/authToken',
      { token, code: token },
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' }),
      }
    );

  tokenLogin = (token: string) => {
    return this.apiService
      .post<LoginResponse>(
        '/oauth2/loginToken',
        { token },
        {
          headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' }),
        }
      )
      .pipe(tap(() => this.login$.next(token)));
  };
}
