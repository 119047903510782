<skudo-auth-wrapper [type]="type">
    <div id="allContainer">
        <div class="card-auth m-form">
            <h1 class="title"><span i18n>Esto es </span><span class="skudo">Skudo</span></h1>
            <p class="descript">
                <span i18n>Modifica la contraseña de la cuenta {{ email }}.</span>
            </p>
            <div class="form-group m-form__group m-form__group--md">
                <platform-notification-feedback
                    [notifications]="notifications.notifications"
                ></platform-notification-feedback>
                <form #form="ngForm" (submit)="onSubmit($event)">
                <div emma-label label="Contraseña">
                    <emma-input 
                        type="password"
                        [(ngModel)]="password"
                        ($change)="showWarning()"
                        [required]="true"
                        name="password"
                    ></emma-input>
                </div>
                <div emma-label label="Confirmar contraseña">
                    <emma-input
                        type="password"
                        [(ngModel)]="repeatPassword"
                        ($change)="showWarning()"
                        [required]="true"
                        name="repeatPassword"
                    ></emma-input>
                </div>
                <button 
                    emmaButton
                    sizeButton="lg"
                    color="skudodarkbluewhite"
                    [isBlock]="true"
                    [type]="type"
                    [loading]="loading.size"
                    [disabled]="loading.size || !isValid()"
                >
                    <strong i18n>Cambiar contraseña</strong>
                </button>
            </form>
            </div>
            <p class="lead">
                <span i18n>¿Quieres iniciar sesión? </span>
                <a class="m-link" target="_self" href="/login/" i18n>Identifícate</a>
            </p>
        </div>
        <footer>
            <span i18n>Skudo, parte de </span><strong>ARKANA</strong>
        </footer>
    </div>
</skudo-auth-wrapper>
