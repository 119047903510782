import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { OnUnsubscribe } from '@platform/helpers/on-unsubscribe.class';
import { apiFeedback } from '@platform/helpers/api-feedback.helper';
import { AUTH_TYPE } from '../auth-wrapper.component';
import { NotificationsService } from '@platform/services/notifications.service';
import { UserService } from '@skudo/services/user.service';
import { STATUS } from 'emma-common-ts';
import { Router } from '@angular/router';
import { ROUTE } from 'emma-common-ts/skudo';
import { getNotificationError, getNotificationsPassword } from '@skudo/helpers/auth.helper';
import { getApiBaseUrl } from '@platform/helpers/base-url.helper';
import { API_URL } from '@platform/providers/url.provider';
import { getCurrentLocale } from '@platform/services/locale.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from '@skudo-environments/environment';

@Component({
  selector: 'skudo-register-page',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SkudoRegisterPageComponent extends OnUnsubscribe implements OnInit{
  @ViewChild('form', { static: false }) public form!: NgForm;
  loading = new Set<string>();
  type = AUTH_TYPE.REGISTER;

  password = '';
  repeatPassword = '';
  email = '';
  agree = false;
  notificationsKey = 'passwordFeedback';
  isPasswordValid = false;
  googleCallbackUrl = '';
  googleButtonLocale = '';
  ROUTE = ROUTE;
  siteKey = environment.recaptchaSiteKey;
  captchaResponse: string = '';

  constructor(
    public notifications: NotificationsService,
    private userService: UserService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    @Inject(API_URL) apiUrl: string
  ) {
    super();
    const googleCallback = getApiBaseUrl(apiUrl);
    this.googleCallbackUrl = googleCallback.endsWith('/')
      ? `${googleCallback}user/sign-up/google/callback`
      : `${googleCallback}/user/sign-up/google/callback`;
    this.googleButtonLocale = getCurrentLocale();
  }

  ngOnInit(): void {
    this.getTokenRecaptcha()  
  }


  onSubmit(ev: Event) {
    ev.preventDefault();
    if (this.isValid()) {
      this.onSave();
    }
  }

  getTokenRecaptcha(){
    this.recaptchaV3Service.execute('register')
      .subscribe((token: string) => (this.captchaResponse = token));
   
  }

  onSave() {
    const singUpData = {
      email: this.email,
      password: this.password,
      agree: this.agree,
      token: this.captchaResponse,
    };

    this.loading.add('signup');
    this.userService
      .signUp(singUpData)
      .pipe(apiFeedback('signup', this.notifications, this.loading))
      .subscribe((data) => {
        this.navigateToLogin(data.status === STATUS.SUCCESS);
      });
  }

  isValid(): boolean {
    return Boolean(this.form?.valid && this.isPasswordValid);
  }

  showWarning() {
    const notifications = getNotificationsPassword(this.notificationsKey, this.password, this.repeatPassword);
    this.isPasswordValid = notifications.length === 0;
    if (this.isPasswordValid) {
      this.notifications.delete(this.notificationsKey);
    } else {
      notifications.forEach((notification) => this.notifications.set(this.notificationsKey, notification));
    }
  }

  verifyEmail() {
    if (this.form.form.controls['email'].status === 'VALID') {
      this.userService.verifyIfUserEmailExists(this.email).subscribe((response) => {
        if (response.status === STATUS.SUCCESS) {
          if (response.data?.isRegistered) {
            this.notifications.set(
              'emailFeedback',
              getNotificationError('emailFeedback', $localize`Este correo ya ha sido registrado.`)
            );
          } else {
            this.notifications.delete('emailFeedback');
          }
        }
      });
    }
  }
  private navigateToLogin(signUpSuccess?: boolean) {
    const params = signUpSuccess ? '?signUpSuccess=true' : '';
    this.router.navigateByUrl(`${ROUTE.LOGIN}${params}`).catch((error) => console.error(error));
  }
}
