import { HttpErrorResponse } from '@angular/common/http';
import { getErrorFromResponse } from '@platform/helpers/response-error.helper';
import { getMessage } from '@platform/i18n/api-errors.i18n';
import { NotificationsService } from '@platform/services/notifications.service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

const getError = (res: any): string => {
  const errorCode = res instanceof HttpErrorResponse ? res.error.code : res?.code;
  if (errorCode) {
    const message = getMessage(errorCode);
    if (message) {
      return message;
    }
  }
  return getErrorFromResponse(res);
};

export function apiFeedback<T>(
  key: string,
  notifications?: NotificationsService,
  loading?: Set<string>
): (obs: Observable<T>) => Observable<T> {
  return (obs: Observable<T>) =>
    obs.pipe(
      tap((res) => {
        if (notifications) {
          notifications.setError(key, getError(res));
        }
        if (loading) {
          if (res === null) {
            loading.add(key);
          } else {
            loading.delete(key);
          }
        }
        return res;
      }),
      catchError((error) => {
        if (notifications) {
          notifications.setError(key, getError(error));
        }
        if (loading) {
          loading.delete(key);
        }
        return throwError(() => error);
      })
    );
}
