import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EMMALoadingModule } from '@emma-components/emma-loading.module';
import { EMMAPortletModule } from '@emma-components/emma-portlet.module';
import { EMMAChartDirective } from '@emma-directives/emma-chart.directive';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';
import { EMMAPipesModule } from '@emma-pipes/emma-pipes.module';
import { Daterangepicker } from 'ng2-daterangepicker';

import { EMMABarsChartComponent } from './emma-barschart.component';
import { EMMABigDescriptedValueComponent } from './emma-bigdescriptedvalue.component';
import { EMMAChartLayoutModule } from './emma-chart-layout.module';
import { EMMAChartComponent } from './emma-chart.component';
import { EMMACoverChartComponent } from './emma-coverchart.component';
import { EMMADescriptedValueComponent } from './emma-descriptedvalue.component';
import { EMMADonutChartComponent } from './emma-donutchart.component';
import { EMMAFunnelChartComponent } from './emma-funnelchart.component';
import { EMMAHalfDonutChartComponent } from './emma-halfdonutchart.component';
import { EMMALinesChartComponent } from './emma-lineschart.component';
import { EMMAMiniChartComponent } from './emma-minichart.component';
import { EMMAQuickstatComponent } from './emma-quickstat.component';
import { EMMAStackBarsChartComponent } from './emma-stackbarschart.component';
import { EMMATrapezoidChartComponent } from './emma-trapezoidchart.component';
import { EMMAMapChartComponent } from './emma-mapchart.component';

@NgModule({
  imports: [
    CommonModule,
    Daterangepicker,
    EMMAPortletModule,
    EMMALoadingModule,
    EMMAChartLayoutModule,
    EMMADirectivesModule,
    EMMAPipesModule,
  ],
  exports: [
    EMMAChartComponent,
    EMMAChartDirective,
    EMMADescriptedValueComponent,
    EMMABigDescriptedValueComponent,
  ],
  declarations: [
    EMMAChartComponent,
    EMMAChartDirective,
    EMMABarsChartComponent,
    EMMAStackBarsChartComponent,
    EMMABigDescriptedValueComponent,
    EMMACoverChartComponent,
    EMMADescriptedValueComponent,
    EMMADonutChartComponent,
    EMMAHalfDonutChartComponent,
    EMMALinesChartComponent,
    EMMAMiniChartComponent,
    EMMAQuickstatComponent,
    EMMATrapezoidChartComponent,
    EMMAFunnelChartComponent,
    EMMAMapChartComponent,
  ],
})
export class EMMAChartModule {}
