import {
  AfterContentChecked,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
} from '@angular/core';

import { EMMAFormElementComponent } from './emma-form-element.component';

@Component({
  selector: '[emma-label]',
  templateUrl: './emma-label.component.html',
})
export class EMMALabelComponent implements OnInit, OnChanges, AfterContentChecked {
  @ContentChildren(EMMAFormElementComponent, { descendants: true })
  _elements!: QueryList<EMMAFormElementComponent>;

  // Force states
  @Input() required = false;
  @Input() disabled = false;
  @Input() hiddenLabel = false;
  @Input() removeOnEmpty = false;

  // Internal states
  innerRequired = false;
  innerDisabled = false;

  // Label
  @Input() first = false;
  @Input() label = '';
  @Input() helpText = '';
  @Input() helpLink = 'https://docs.emma.io';
  @Input() helpLinkLabel = $localize`Saber más`;

  @HostBinding('class') containerClass = 'form-group m-form__group m-form__group--md';
  _containerClass = '';

  ngOnInit(): void {
    this.updateClasses();
  }
  ngAfterContentChecked(): void {
    this.updateProps();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if ('labelClass' in changes || 'color' in changes) {
      this.updateClasses();
    }
    if ('required' in changes || 'disabled' in changes) {
      this.updateProps();
    }
  }

  /**
   * Update CSS classes
   */
  private updateClasses() {
    this._containerClass = `emma-label ${this.containerClass} ${this.first ? 'm-form__group--first' : ''}`;
  }

  /**
   * Calculate props
   */
  private updateProps() {
    this.innerRequired = Boolean(
      this.required || (this._elements?.length && this._elements.some((el) => Boolean(el.required)))
    );
    this.innerDisabled = Boolean(
      this.disabled || (this._elements?.length && !this._elements.some((el) => !el.disabled))
    );
  }
}
