import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';
import { difference, uniq } from 'lodash';

@Directive({
  selector: '[emmaButton]',
})
export class EMMAButtonDirective implements OnChanges {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('class')
  set klass(value: string) {
    this._rawClass = value.split(/\s+/);
    this._setClasses();
  }
  @Input()
  set loading(value: unknown) {
    this._loading = Boolean(value);
    this._setDisabled();
    this._setClasses();
  }
  @Input()
  set disabled(value: unknown) {
    this._disabled = Boolean(value);
    this._setDisabled();
    this._setClasses();
  }

  @Input() type = 'button';
  @Input() role = 'button';
  @Input() color: 'metal' | 'brand' | 'danger' | 'emmablue' | 'cancel' | 'inverse-emmadarkblue' = 'metal';
  @Input() sizeButton?: 'xs' | 'sm' | 'lg';
  @Input() noBg = false;
  @Input() hasIcon = false;
  @Input() onlyIcon = false;
  @Input() isWide = false;
  @Input() isCircle = false;
  @Input() isBlock = false;
  @Input() isLink = false;
  @Input() isOutline = false;

  private _rawClass: string[] = [];
  private _disabled = false;
  private _loading = false;

  constructor(private _el: ElementRef, private _renderer: Renderer2) {}

  ngOnChanges(): void {
    this._setAttribute('type', this.type);
    this._setAttribute('role', this.role);
    this._setClasses();
  }

  private _setClasses() {
    const classes = [
      ...this._rawClass,
      'btn',
      'm-btn',
      this.isWide && 'm-btn--wide',
      this.isBlock && 'btn-block',
      this.isCircle && 'm-btn--pill',
      this.isOutline && 'm-btn--outline-2x',
      this.hasIcon && 'm-btn--icon',
      this.hasIcon && this.onlyIcon && 'm-btn--icon-only',
      this.sizeButton && `btn-${this.sizeButton}`,
      this._loading && ['m-loader', 'm-loader--right', 'm-loader--light'],
    ];
    if (this.color) {
      if (this.color === 'cancel') {
        classes.push('m-btn-link-danger');
      } else if (!this.noBg) {
        if (this.isLink) {
          classes.push(`m-btn-link-${this.color}`);
        } else if (this.isOutline) {
          classes.push(`btn-outline-${this.color}`);
        } else {
          classes.push(`btn-${this.color}`);
        }
      } else if (!this.isLink) {
        classes.push(`m-btn--hover-${this.color}`);
      }
    }
    this._renderer.setAttribute(this._el.nativeElement, 'class', '');
    const flat: string[] = classes.filter(Boolean).flat() as string[];
    this._applyClasses(uniq(flat));
  }
  private _applyClasses(classes: string[] = []) {
    const { nativeElement } = this._el;
    const currentClasses = this._el.nativeElement.getAttribute('class');
    const _remove = difference(currentClasses || [], classes || []);
    const _add = difference(classes || [], currentClasses || []);
    for (const name of _remove) {
      this._renderer.removeClass(nativeElement, name);
    }
    for (const name of _add) {
      this._renderer.addClass(nativeElement, name);
    }
  }
  private _setDisabled() {
    this._setAttribute('disabled', this._disabled || this._loading);
  }
  private _setAttribute(name: string, value: string | boolean) {
    if (value) {
      this._renderer.setAttribute(this._el.nativeElement, name, value.toString());
    } else {
      this._renderer.removeAttribute(this._el.nativeElement, name);
    }
  }
}
